<template>
  <section id="patient-dashboard-container">
    <div class="breadcrumbs">
      <router-link to="/glauco/dashboard" tag="span" class="link">
        Glauco
      </router-link>
      <ChevronRight class="chevron" />
      <span>{{ patient.name }}</span>
    </div>
    <b-container fluid>
      <b-row>
        <b-col lg="4" sm="12">
          <PatientCard
            :patientId="patientId"
            :clinicId="clinic.id"
            @update-patient-name="updatePatientName"
          />
          <GlaucoResumePatient :eyeCareBIPatient="patient" />
        </b-col>

        <b-col lg="8" sm="12">
          <PatientTreatments @change-user-treatments="changeTreatments" />

          <TreatmentRequests :eyeCareBIPatient="patient" />

          <AdherenceMap />

          <PatientMedications :treatments="treatments" />

          <AdditionalInfo />

          <Symptoms />
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager.js'

export default {
  metaInfo: {
    title: 'Eyecare - Dashboard do paciente Glauco'
  },
  name: 'PatientDashboard',
  components: {
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    PatientCard: () => import('@/components/Glauco/Dashboard/Patient/PatientCard'),
    GlaucoResumePatient: () => import('@/layouts/Glauco/Dashboard/Patient/GlaucoResumePatient'),
    PatientTreatments: () => import('@/components/Glauco/Dashboard/Patient/PatientTreatments'),
    TreatmentRequests: () => import('@/layouts/Glauco/Dashboard/Patient/TreatmentRequests'),
    AdherenceMap: () => import('@/layouts/Glauco/Dashboard/Patient/AdherenceMap'),
    Symptoms: () => import('@/layouts/Glauco/Dashboard/Patient/Symptoms'),
    PatientMedications: () => import('@/layouts/Glauco/Dashboard/Patient/PatientMedications'),
    AdditionalInfo: () => import('@/layouts/Glauco/Dashboard/Patient/AdditionalInfo'),
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      patient: {},
      patientId: this.$route.params.patientId,
      treatments: []
    }
  },
  methods: {
    updatePatientName(patient) {
      this.patient = patient
    },
    changeTreatments(treatments) {
      this.treatments = treatments
    }
  }
}
</script>

<style lang="scss" scoped>
#patient-dashboard-container {
  height: 100%;
  padding: 30px 24px;

  .breadcrumbs {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
